import moment from "moment";

export const INITIAL_PARAMS = {
  limit: 10,
  page: 1,
};

export const INITIAL_COMPLIMENT_PARAMS = {
  type: "DAILY",
  date: moment().format("YYYY-MM-DD"),
  isDownload: false,
};

export const INITIAL_MODAL = {
  show: false,
  id: null,
};
