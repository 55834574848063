import saMessages from "../locales/it_IT.json";
import itIT from "antd/lib/locale/it_IT";

const saLang = {
  messages: {
    ...saMessages,
  },
  antLocale: itIT,
  locale: "it-IT",
};
export default saLang;
