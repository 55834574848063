import React from "react";
import "./index.style.less";
import PropTypes from "prop-types";
import { useSidebarContext } from "../../../../utility/AppContextProvider/SidebarContextProvider";

const AppLogo = ({ hasSidebarColor }) => {
  const { sidebarColorSet } = useSidebarContext();
  return (
    <div className="app-logo">
      {hasSidebarColor && sidebarColorSet.mode === "dark"
        ? (
          <img src="/assets/images/logo-white-with-name.png" alt="crema-logo" />
        )
        : (
          <img src="/assets/images/rooftop-64.ico" alt="crema-logo" />
        )}
    </div>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
