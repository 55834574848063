import {
  CLEAR_TRANSACTION_LIST,
  CLEAR_TRANSACTION_DETAIL,
  GET_TRANSACTION_LIST,
  GET_TRANSACTION_DETAIL
} from "shared/constants/ActionTypes";

const initialState = {
  transactionList: {
    data: [],
    pagination: {},
  },
  transactionDetail: {},
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_TRANSACTION_LIST:
    return {
      ...state,
      transactionList: action.payload,
    };

  case GET_TRANSACTION_DETAIL:
    return {
      ...state,
      transactionDetail: action.payload,
    };

  case CLEAR_TRANSACTION_LIST:
    return {
      ...state,
      transactionList: { ...initialState.transactionList },
    };

  case CLEAR_TRANSACTION_DETAIL:
    return {
      ...state,
      transactionDetail: {},
    };

  default:
    return state;
  }
};

export default transactionReducer;
