import axios from "services/axios";

export const transactionList = (params) =>
  axios.get("/transactions", {
    params,
  });

export const transactionDetail = (id) => axios.get(`/transactions/${id}`);

export const transactionCreate = (values) => axios.post("/transactions", values);

export const transactionEdit = (id, values) => axios.patch(`/transactions/${id}`, values);

export const transactionDelete = (id) => axios.delete(`/transactions/${id}`);
