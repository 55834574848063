import axios from "services/axios";

export const reportComplimentList = (params) =>
  axios.get("/report/compliment", {
    params,
    responseType: "json",
  });

export const reportComplimentDetail = (id) => axios.get(`/report/compliment/${id}`);

export const reportComplimentEdit = (id, values) => axios.patch(`/report/compliment/${id}`, values, {
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const reportComplimentCreate = (values) => axios.post("/report/compliment", values, {
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const reportComplimentDelete = (id) => axios.delete(`/report/compliment/${id}`);
