// API
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";
export const TOGGLE_APP_DRAWER = "toggle_app_drawer";
export const UPDATING_CONTENT = "updating_content";

// APP SETTING
export const TOGGLE_NAV_COLLAPSED = "toggle_nav_collapsed";
export const SET_INITIAL_PATH = "set_initial_path";

// User
export const CLEAR_USER_LIST = "CLEAR_USER_LIST";
export const CLEAR_USER_DETAIL = "CLEAR_USER_DETAIL";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_DETAIL = "GET_USER_DETAIL";

// Branch
export const CLEAR_BRANCH_LIST = "CLEAR_BRANCH_LIST";
export const CLEAR_BRANCH_DETAIL = "CLEAR_BRANCH_DETAIL";
export const GET_BRANCH_LIST = "GET_BRANCH_LIST";
export const GET_BRANCH_DETAIL = "GET_BRANCH_DETAIL";

// Category
export const CLEAR_CATEGORY_LIST = "CLEAR_CATEGORY_LIST";
export const CLEAR_CATEGORY_DETAIL = "CLEAR_CATEGORY_DETAIL";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_DETAIL = "GET_CATEGORY_DETAIL";

// Menu
export const CLEAR_MENU_LIST = "CLEAR_MENU_LIST";
export const CLEAR_MENU_DETAIL = "CLEAR_MENU_DETAIL";
export const GET_MENU_LIST = "GET_MENU_LIST";
export const GET_MENU_DETAIL = "GET_MENU_DETAIL";

// Menu Packages
export const CLEAR_PACKAGE_LIST = "CLEAR_PACKAGE_LIST";
export const CLEAR_PACKAGE_DETAIL = "CLEAR_PACKAGE_DETAIL";
export const GET_PACKAGE_LIST = "GET_PACKAGE_LIST";
export const GET_PACKAGE_DETAIL = "GET_PACKAGE_DETAIL";

// Transaction
export const CLEAR_TRANSACTION_LIST = "CLEAR_TRANSACTION_LIST";
export const CLEAR_TRANSACTION_DETAIL = "CLEAR_TRANSACTION_DETAIL";
export const GET_TRANSACTION_LIST = "GET_TRANSACTION_LIST";
export const GET_TRANSACTION_DETAIL = "GET_TRANSACTION_DETAIL";

// Menu
export const CLEAR_REPORT_COMPLIMENT_LIST = "CLEAR_REPORT_COMPLIMENT_LIST";
export const CLEAR_REPORT_COMPLIMENT_DETAIL = "CLEAR_REPORT_COMPLIMENT_DETAIL";
export const GET_REPORT_COMPLIMENT_LIST = "GET_REPORT_COMPLIMENT_LIST";
export const GET_REPORT_COMPLIMENT_DETAIL = "GET_REPORT_COMPLIMENT_DETAIL";
