import React from "react";
import PropTypes from "prop-types";
import { Avatar, Badge } from "antd";
import AppTableContainer from "@crema/core/AppTableContainer";
import { OrderActions } from "components/organisms";

const UserTable = ({ data, loading, onEdit, onDetail, onDelete }) => {
  const columns = [
    {
      title: "Avatar",
      dataIndex: "avatar",
      key: "avatar",
      render: (text) => <Avatar src={text} />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (status) => (
        <Badge
          status={status ? "success" : "default"}
          text={status ? "AKTIF" : "TIDAK AKTIF"}
        />
      ),
    },
    {
      title: "Aksi",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (_, { id }) => (
        <OrderActions
          onDetail={() => onDetail(id)}
          onEdit={() => onEdit(id)}
          onDelete={() => onDelete(id)}
        />
      ),
    },
  ];

  return (
    <AppTableContainer
      hoverColor
      data={data}
      columns={columns}
      loading={loading}
      scroll={{ x: "auto" }}
    />
  );
};

export default UserTable;

UserTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onDetail: PropTypes.func,
  onDelete: PropTypes.func,
};

UserTable.defaultProps = {
  data: [],
  loading: false,
};
