import {
  CLEAR_REPORT_COMPLIMENT_LIST,
  CLEAR_REPORT_COMPLIMENT_DETAIL,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
  GET_REPORT_COMPLIMENT_LIST,
  GET_REPORT_COMPLIMENT_DETAIL
} from "shared/constants/ActionTypes";
import {
  reportComplimentCreate,
  reportComplimentDelete,
  reportComplimentDetail,
  reportComplimentEdit,
  reportComplimentList
} from "services/backend/reportCompliment";

// Clear states
export const onClearReportComplimentList = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_REPORT_COMPLIMENT_LIST });
  };
};

export const onClearReportComplimentDetail = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_REPORT_COMPLIMENT_DETAIL });
  };
};

// ReportCompliment list
export const onGetReportComplimentList = (params) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await reportComplimentList(params);

      if (response.status === 200) {
        const { data, pagination } = response.data.results;
        const payload = { data, pagination };

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_REPORT_COMPLIMENT_LIST, payload: payload });
        return Promise.resolve(payload);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};

// ReportCompliment detail
export const onGetReportComplimentDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await reportComplimentDetail(id);

      if (response.status === 200) {
        const { data } = response;
        const payload = data.results;

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_REPORT_COMPLIMENT_DETAIL, payload: payload });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

// ReportCompliment create
export const onCreateReportCompliment = (values) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await reportComplimentCreate(values);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil membuat ReportCompliment baru!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};

// ReportCompliment edit
export const onEditReportCompliment = (id, values) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await reportComplimentEdit(id, values);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil mengedit ReportCompliment!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      const { status } = error.response;

      if (status === 406) {
        dispatch({ type: FETCH_ERROR, payload: "Email sudah ada sebelumnya!" });
      } else {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      }
      return Promise.reject(error);
    }
  };
};

// User delete
export const onDeleteReportCompliment = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await reportComplimentDelete(id);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil menghapus ReportCompliment!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};
