import {
  CLEAR_PACKAGE_LIST,
  CLEAR_PACKAGE_DETAIL,
  GET_PACKAGE_LIST,
  GET_PACKAGE_DETAIL
} from "shared/constants/ActionTypes";

const initialState = {
  packageList: {
    data: [],
    pagination: {},
  },
  packageDetail: {},
};

const packageReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_PACKAGE_LIST:
    return {
      ...state,
      packageList: action.payload,
    };

  case GET_PACKAGE_DETAIL:
    return {
      ...state,
      packageDetail: action.payload,
    };

  case CLEAR_PACKAGE_LIST:
    return {
      ...state,
      packageList: { ...initialState.packageList },
    };

  case CLEAR_PACKAGE_DETAIL:
    return {
      ...state,
      packageDetail: {},
    };

  default:
    return state;
  }
};

export default packageReducer;
