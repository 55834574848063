import {
  CLEAR_CATEGORY_LIST,
  CLEAR_CATEGORY_DETAIL,
  GET_CATEGORY_LIST,
  GET_CATEGORY_DETAIL
} from "shared/constants/ActionTypes";

const initialState = {
  categoryList: {
    data: [],
    pagination: {},
  },
  categoryDetail: {},
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_CATEGORY_LIST:
    return {
      ...state,
      categoryList: action.payload,
    };

  case GET_CATEGORY_DETAIL:
    return {
      ...state,
      categoryDetail: action.payload,
    };

  case CLEAR_CATEGORY_LIST:
    return {
      ...state,
      categoryList: { ...initialState.categoryList },
    };

  case CLEAR_CATEGORY_DETAIL:
    return {
      ...state,
      categoryDetail: {},
    };

  default:
    return state;
  }
};

export default categoryReducer;
