import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Col, Descriptions } from "antd";
import { AppRowContainer } from "@crema";
import AppCard from "@crema/core/AppCard";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import { useDispatch, useSelector } from "react-redux";
import { onClearTransactionDetail, onGetTransactionDetail } from "redux/actions/Transaction";
import MenuTable from "./Menu/MenuTable";
import { RPFormat } from "utils/helpers";

const TransactionDetail = () => {
  const { transactionDetail } = useSelector(({ transaction }) => transaction);
  const menus = transactionDetail.detail?.map(({ id, menuName, image, price, amount, note }) => ({
    id,
    name: {
      image,
      menuName,
      amount,
      price,
    },
    note,
  }));
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(onGetTransactionDetail(params.id));
  }, [dispatch, params.id]);

  useEffect(
    () => () => {
      dispatch(onClearTransactionDetail());
    },
    [dispatch]
  );

  return (
    <>
      <AppPageMetadata title="Detail Transaksi" />
      <AppsContainer title="Detail Menu" fullView type="bottom">
        <AppRowContainer>
          <Col span={24}>
            <AppCard title={transactionDetail.id}>
              <Descriptions
                title={`Meja ${transactionDetail.tableNo} - ${transactionDetail.customerName}`}
                layout="vertical"
                style={{ padding: "0 15px" }}
              >
                <Descriptions.Item label="No Handphone">
                  {transactionDetail.customerPhone}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {transactionDetail.customerName}
                </Descriptions.Item>
                <Descriptions.Item label="Status Pembayaran">
                  {transactionDetail.payment}
                </Descriptions.Item>
                <Descriptions.Item label="Pembayaran">
                  {transactionDetail.payment}
                </Descriptions.Item>
                <Descriptions.Item label="Tanggal Order">
                  {moment(transactionDetail.createdAt).format("YYYY/MM/DD - h:M:s")}
                </Descriptions.Item>
                <Descriptions.Item label="Total Pembayaran">
                  {RPFormat(transactionDetail.totalPrice)}
                </Descriptions.Item>
              </Descriptions>

              <MenuTable data={menus} />
            </AppCard>
          </Col>
        </AppRowContainer>
      </AppsContainer>
    </>
  );
};

export default TransactionDetail;
