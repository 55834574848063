import React, { useEffect } from "react";
import { Col, Descriptions, Image, Row, Space } from "antd";
import { AppRowContainer } from "@crema";
import AppCard from "@crema/core/AppCard";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import { useDispatch, useSelector } from "react-redux";
import { onClearMenuDetail, onGetMenuDetail } from "redux/actions/Menu";
import { useParams } from "react-router-dom";
import { RPFormat } from "utils/helpers";

const MenuDetail = () => {
  const { menuDetail } = useSelector(({ menu }) => menu);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(onGetMenuDetail(params.id));
  }, [dispatch, params.id]);

  useEffect(
    () => () => {
      dispatch(onClearMenuDetail());
    },
    [dispatch]
  );
  return (
    <>
      <AppPageMetadata title="Menu Detail" />
      <AppsContainer title="Detail Menu" fullView type="bottom">
        <AppRowContainer>
          <Col span={24}>
            <AppCard title={menuDetail.name}>
              <Row>
                <Col span={6}>
                  <Image
                    width="100%"
                    src={menuDetail.image || "https://via.placeholder.com/150"}
                  />
                </Col>
                <Col span={18}>
                  <div
                    style={{ padding: "0 15px" }}>
                    <Descriptions
                      title="Menu Info"
                      layout="vertical"
                    >
                      <Descriptions.Item span={3} label="Deskripsi">
                        {menuDetail.description || "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Kategori">
                        {menuDetail.categories
                          ?.map(({ name }) => name)
                          .join(", ") || "-"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Tipe">
                        {menuDetail.type}
                      </Descriptions.Item>
                      <Descriptions.Item label="Status">
                        {menuDetail.status}
                      </Descriptions.Item>
                    </Descriptions>
                    <div>
                      <div style={{ marginBottom: "6px" }}>Cabang: </div>
                      <Space direction="vertical">
                        {menuDetail.branch?.map(({ branchId, name, sellingPrice, stock }) => (
                          <div key={branchId}>
                            <span>{name} </span>
                            <span>| Harga Jual: {RPFormat(sellingPrice)} </span>
                            <span>| Stock: {stock} </span>
                          </div>
                        ))}
                      </Space>
                    </div>
                  </div>
                </Col>
              </Row>
            </AppCard>
          </Col>
        </AppRowContainer>
      </AppsContainer>
    </>
  );
};

export default MenuDetail;
