import {
  CLEAR_BRANCH_LIST,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  GET_BRANCH_LIST,
  SHOW_MESSAGE,
  GET_BRANCH_DETAIL
} from "shared/constants/ActionTypes";
import {
  branchCreate,
  branchDelete,
  branchDetail,
  branchEdit,
  branchList
} from "services/backend/branch";

export const onClearBranchList = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_BRANCH_LIST });
  };
};

export const onGetBranchList = (params) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await branchList(params);

      if (response.status === 200) {
        const { data, pagination } = response.data.results;
        const payload = { data, pagination };

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_BRANCH_LIST, payload: payload });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

// User detail
export const onGetBranchDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await branchDetail(id);

      if (response.status === 200) {
        const { data } = response;
        const payload = data.results;

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_BRANCH_DETAIL, payload: payload });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

// User create
export const onCreateBranch = (values) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await branchCreate(values);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil membuat Cabang baru!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};

// User Edit
export const onEditBranch = (id, values) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await branchEdit(id, values);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil mengedit Cabang!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};

// User delete
export const onDeleteBranch = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await branchDelete(id);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil menghapus Cabang!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};
