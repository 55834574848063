import React from "react";
import PropTypes from "prop-types";
import AppTableContainer from "@crema/core/AppTableContainer";
import { OrderActions } from "components/organisms";
import { RPFormat } from "utils/helpers";

const MenuTable = ({ data, loading, onEdit, onDetail, onDelete }) => {
  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tipe",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Harga",
      dataIndex: "sellingPrice",
      key: "sellingPrice",
      render: (text) => RPFormat(text),
    },
    {
      title: "Stok",
      dataIndex: "stock",
      key: "stock",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Aksi",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (_, { id }) => (
        <OrderActions
          onDetail={() => onDetail(id)}
          onEdit={() => onEdit(id)}
          onDelete={() => onDelete(id)}
        />
      ),
    },
  ];

  return (
    <AppTableContainer
      hoverColor
      data={data}
      columns={columns}
      loading={loading}
      scroll={{ x: "auto" }}
    />
  );
};

export default MenuTable;

MenuTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onDetail: PropTypes.func,
  onDelete: PropTypes.func,
};

MenuTable.defaultProps = {
  users: [],
  loading: false,
};
