import {
  CLEAR_USER_LIST,
  CLEAR_USER_DETAIL,
  SHOW_MESSAGE,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_USER_LIST,
  GET_USER_DETAIL
} from "shared/constants/ActionTypes";
import {
  userCreate,
  userDelete,
  userDetail,
  userEdit,
  userList
} from "services/backend/auth";

// Clear states
export const onClearUserList = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_USER_LIST });
  };
};

export const onClearUserDetail = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_USER_DETAIL });
  };
};

// User list
export const onGetUserList = (params) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await userList(params);

      if (response.status === 200) {
        const { data, pagination } = response.data.results;
        const payload = { data, pagination };

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_USER_LIST, payload: payload });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

// User detail
export const onGetUserDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await userDetail(id);

      if (response.status === 200) {
        const { data } = response;
        const payload = data.results;

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_USER_DETAIL, payload: payload });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

// User create
export const onCreateUser = (values) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await userCreate(values);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil membuat User baru!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      const { status } = error.response;

      if (status === 406) {
        dispatch({ type: FETCH_ERROR, payload: "Email sudah ada sebelumnya!" });
      } else {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      }
      return Promise.reject(error);
    }
  };
};

// User Edit
export const onEditUser = (id, values) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await userEdit(id, values);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil mengedit User!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      const { status } = error.response;

      if (status === 406) {
        dispatch({ type: FETCH_ERROR, payload: "Email sudah ada sebelumnya!" });
      } else {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      }
      return Promise.reject(error);
    }
  };
};

// User delete
export const onDeleteUser = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await userDelete(id);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil menghapus User!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};
