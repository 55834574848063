import {
  CLEAR_REPORT_COMPLIMENT_LIST,
  CLEAR_REPORT_COMPLIMENT_DETAIL,
  GET_REPORT_COMPLIMENT_LIST,
  GET_REPORT_COMPLIMENT_DETAIL
} from "shared/constants/ActionTypes";

const initialState = {
  reportComplimentList: {
    data: [],
    pagination: {},
  },
  reportComplimentDetail: {},
};

const reportComplimentReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_REPORT_COMPLIMENT_LIST:
    return {
      ...state,
      reportComplimentList: action.payload,
    };

  case GET_REPORT_COMPLIMENT_DETAIL:
    return {
      ...state,
      reportComplimentDetail: action.payload,
    };

  case CLEAR_REPORT_COMPLIMENT_LIST:
    return {
      ...state,
      reportComplimentList: { ...initialState.reportComplimentList },
    };

  case CLEAR_REPORT_COMPLIMENT_DETAIL:
    return {
      ...state,
      reportComplimentDetail: {},
    };

  default:
    return state;
  }
};

export default reportComplimentReducer;
