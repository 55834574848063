import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, Space } from "antd";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import AppCard from "@crema/core/AppCard";
import { AppRowContainer } from "@crema";
import {
  onClearUserDetail,
  onCreateUser,
  onEditUser,
  onGetUserDetail
} from "redux/actions/User";

const UserEdit = () => {
  const { loading } = useSelector(({ common }) => common);
  const { userDetail } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();

  useEffect(async () => {
    if (params.id) {
      dispatch(onGetUserDetail(params.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      const { name, email } = userDetail;
      form.setFieldsValue({ name, email });
    }
  }, [userDetail]);

  useEffect(() => {
    return () => {
      dispatch(onClearUserDetail());
    };
  }, [dispatch]);

  const onFinish = async (values) => {
    values.isActive = true; // ! isActice di hardcode sementara karna dari BE tidak otomatis

    try {
      if (params.id) {
        await dispatch(onEditUser(params.id, values));
      } else {
        await dispatch(onCreateUser(values));
        form.resetFields();
      }
      navigate("/users");
    } catch (error) {
      //
    }
  };

  return (
    <>
      <AppPageMetadata title="User Edit" />
      <AppsContainer
        title={`${params.id ? "Edit" : "Tambah"} User`}
        fullView
        type="bottom"
      >
        <AppRowContainer>
          <Col span={24}>
            <AppCard title="Form">
              <Form
                form={form}
                className="edit-table-form"
                name="user"
                layout="vertical"
                onFinish={onFinish}
              >
                <Space direction="vertical" size={15} className="w-full">
                  <Form.Item
                    name="name"
                    label="Nama Lengkap"
                    rules={[
                      { required: true, message: "Masukan nama lengkap Anda!" },
                    ]}
                  >
                    <Input placeholder="Nama lengkap" />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      { required: true, message: "Masukan email Anda!" },
                      { type: "email", message: "Email tidak valid!" },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      { min: 8, message: "Password minimal 8 karakter!" },
                    ]}
                  >
                    <Input type="password" placeholder="Password" />
                  </Form.Item>

                  <Form.Item
                    name="confirmPassword"
                    label="Konfirmasi Password"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator (_, value) {
                          const password = getFieldValue("password");

                          if (!value || password === value) {
                            return Promise.resolve();
                          }
                          if (password.length > 1 && !value) {
                            return Promise.reject(
                              new Error("Masukan konfirmasi password Anda!")
                            );
                          }
                          return Promise.reject(
                            new Error("Password harus sama!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input type="password" placeholder="Konfirmasi Password" />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      SIMPAN
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </AppCard>
          </Col>
        </AppRowContainer>
      </AppsContainer>
    </>
  );
};

export default UserEdit;
