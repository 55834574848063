import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { Modal } from "antd";
import { AppContextProvider, AppThemeProvider, AppLayout } from "./@crema";
import "./@crema/services/index";
import configureStore from "./redux/store";
import { logout } from "utils/helpers";

import "./shared/styles/crema.less";
import AppLocaleProvider from "@crema/utility/AppLocaleProvider";

const store = configureStore();

const App = () => {
  const checkToken = () => {
    const token = localStorage.getItem("userToken");
    if (token) {
      const tokenDecoded = jwtDecode(token);
      const tokenExpire = parseInt(tokenDecoded.exp) * 1000;
      const betweenTwoDate = tokenExpire - Date.now();

      if (betweenTwoDate > 1 || betweenTwoDate <= 0) {
        setTimeout(() => {
          Modal.error({
            title: "EXPIRE TOKEN!",
            content: "SESI KAMU SUDAH HABIS",
            okText: "Oke",
            onOk: () => {
              logout(() => {
                global.window.location.href = "/signin";
              });
            },
          });
        }, betweenTwoDate);
      }
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <AppContextProvider>
      <Provider store={store}>
        <AppThemeProvider>
          <AppLocaleProvider>
            <BrowserRouter>
              <AppLayout />
            </BrowserRouter>
          </AppLocaleProvider>
        </AppThemeProvider>
      </Provider>
    </AppContextProvider>
  );
};

export default App;
