import React from "react";
import { Layout } from "antd";
import { AppSuspense } from "../../index";
import AppErrorBoundary from "../AppErrorBoundary";
import "./index.style.less";
import { Navigate, Route, Routes } from "react-router-dom";
import { initialUrl } from "../../../shared/constants/AppConst";

// Auth
import Signin from "pages/auth/Signin";
import UserProfile from "pages/profile/UserProfile";
import UserList from "pages/application/User/List";
import UserEdit from "pages/application/User/Edit";

// Menu
import MenuList from "pages/application/Menu/List";
import MenuEdit from "pages/application/Menu/Edit";
import MenuDetail from "pages/application/Menu/Detail";

// Packages
import PackageList from "pages/application/Package/List";
import PackageEdit from "pages/application/Package/Edit";

// Category
import CategoryList from "pages/application/Category/List";
import CategoryEdit from "pages/application/Category/Edit";

// Transaction
import TransactionList from "pages/application/Transaction/List";
import TransactionDetail from "pages/application/Transaction/Detail";

// Report
import ReportCompliment from "pages/report/Compliment";
import ReportSales from "pages/report/Sales";

// Etc
import Error404 from "pages/errorPages/Error404";

// Routes
import {
  AuthenticatedRoute,
  UnAuthenticatedRoute
} from "utils/ProtectedRoutes";

const { Content } = Layout;

const AppContentView = () => {
  return (
    <Content className="main-content-view">
      <AppSuspense>
        <AppErrorBoundary>
          <Routes>
            {/* Redirect route */}
            <Route path="/" element={<Navigate to={initialUrl} replace />} />

            {/* Unauthenticated routes */}
            <Route element={<UnAuthenticatedRoute />}>
              <Route path="/signin" element={<Signin />} />
            </Route>

            {/* Authenticated routes */}
            <Route element={<AuthenticatedRoute />}>
              {/* Users */}
              <Route path="/users" element={<UserList />} />
              <Route path="/users/create" element={<UserEdit />} />
              <Route path="/users/:id/edit" element={<UserEdit />} />

              {/* Menus */}
              <Route path="menus" element={<MenuList />} />
              <Route path="/menus/create" element={<MenuEdit />} />
              <Route path="/menus/:id/edit" element={<MenuEdit />} />
              <Route path="/menus/:id/detail" element={<MenuDetail />} />

              {/* Menu Packages */}
              <Route path="/packages" element={<PackageList />} />
              <Route path="/packages/create" element={<PackageEdit />} />
              <Route path="/packages/:id/edit" element={<PackageEdit />} />

              {/* Categories */}
              <Route path="/categories" element={<CategoryList />} />
              <Route path="/categories/create" element={<CategoryEdit />} />
              <Route path="/categories/:id/edit" element={<CategoryEdit />} />

              {/* Transactions */}
              <Route path="/transactions" element={<TransactionList />} />
              <Route path="/transactions/:id/detail" element={<TransactionDetail />} />

              {/* Profile */}
              <Route path="/my-profile" element={<UserProfile />} />

              {/* Report */}
              <Route path="/report/compliment" element={<ReportCompliment />} />
              <Route path="/report/sales" element={<ReportSales />} />
            </Route>

            {/* Error Routes */}
            <Route path="*" element={<Error404 />} />
          </Routes>
        </AppErrorBoundary>
      </AppSuspense>
    </Content>
  );
};

export default AppContentView;
