import React from "react";
import { FiUsers } from "react-icons/fi";
import { IoFastFoodOutline } from "react-icons/io5";
import { TbReportAnalytics, TbReportMoney } from "react-icons/tb";
import { BiCategory, BiPackage } from "react-icons/bi";
import { FaWeight, FaLayerGroup, FaRegObjectUngroup } from "react-icons/fa";

const routesConfig = [
  {
    id: "app",
    title: "Application",
    messageId: "sidebar.sample",
    type: "group",
    children: [
      {
        id: "user",
        title: "Daftar User",
        type: "item",
        icon: <FiUsers />,
        path: "/users",
      },
      {
        id: "menu",
        title: "Daftar Menu",
        type: "item",
        icon: <IoFastFoodOutline />,
        path: "/menus",
      },
      {
        id: "package",
        title: "Menu Paket",
        type: "item",
        icon: <BiPackage />,
        path: "/packages",
      },
      {
        id: "category-menu",
        title: "Daftar Kategori Menu",
        type: "item",
        icon: <BiCategory />,
        path: "/categories",
      },
      {
        id: "unit",
        title: "Satuan",
        type: "item",
        icon: <FaWeight />,
        path: "/satuan",
      },
      {
        id: "material-category",
        title: "Material Category",
        type: "item",
        icon: <BiCategory />,
        path: "/material-category",
      },
      {
        id: "master-material",
        title: "Master Material",
        type: "item",
        icon: <FaLayerGroup />,
        path: "/master-material",
      },
      {
        id: "manage-material",
        title: "Manage Material",
        type: "item",
        icon: <FaRegObjectUngroup />,
        path: "/manage-material",
      },
      {
        id: "transaction",
        title: "Daftar Transaksi",
        type: "item",
        icon: <TbReportMoney />,
        path: "/transactions",
      },
      {
        id: "report",
        title: "Laporan",
        icon: <TbReportAnalytics />,
        path: "report",
        type: "collapse",
        children: [
          {
            id: "report-compliment",
            title: "Laporan Compliment",
            path: "/report/compliment",
          },
          {
            id: "report-sales",
            title: "Laporan Penjualan",
            path: "/report/sales",
          },
        ],
      },
    ],
  },
];
export default routesConfig;
