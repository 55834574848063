import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Setting";
import Common from "./Common";
import User from "./User";
import Branch from "./Branch";
import Category from "./Category";
import Menu from "./Menu";
import Package from "./Package";
import Transaction from "./Transaction";
import ReportCompliment from "./ReportCompliment";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    common: Common,
    user: User,
    branch: Branch,
    category: Category,
    menu: Menu,
    package: Package,
    transaction: Transaction,
    reportCompliment: ReportCompliment,
  });
export default reducers;
