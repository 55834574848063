import axios from "services/axios";

export const branchList = (params) =>
  axios.get("/branch", {
    params,
  });

export const branchDetail = (id) => axios.get(`/branch/${id}`);

export const branchCreate = (values) => axios.post("/branch", values);

export const branchEdit = (id, values) => axios.patch(`/branch/${id}`, values);

export const branchDelete = (id) => axios.delete(`/branch/${id}`);
