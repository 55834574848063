import React, { useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, Modal } from "antd";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import AppsHeader from "@crema/core/AppsContainer/AppsHeader";
import AppsPagination from "@crema/core/AppsPagination";
import AppsContent from "@crema/core/AppsContainer/AppsContent";
import TransactionTable from "./TransactionTable";
import { onClearTransactionList, onGetTransactionList } from "redux/actions/Transaction";
import { INITIAL_PARAMS } from "shared/constants/InitState";

const TransactionList = () => {
  const { loading } = useSelector(({ common }) => common);
  const { transactionList } = useSelector(({ transaction }) => transaction);
  const [params, setParams] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    INITIAL_PARAMS
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(onGetTransactionList(params));
  }, [dispatch, params]);

  useEffect(
    () => () => {
      dispatch(onClearTransactionList());
    },
    [dispatch]
  );

  return (
    <>
      <AppPageMetadata title="Daftar Transaksi" />
      <AppsContainer title="Daftar Transaksi" fullView type="bottom">
        <AppsHeader key="wrap">
          <div className="table-header">
            <div className="table-header-input-view">
              <Input
                id="name"
                placeholder="Search"
                type="search"
                name="name"
                onChange={({ target }) => setParams({ cn: target.value, page: 1 })}
              />
            </div>
          </div>
        </AppsHeader>

        <AppsContent
          key={"wrap1"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <TransactionTable
            loading={loading}
            data={transactionList.data}
            onDetail={(id) => navigate(`/transactions/${id}/detail`)}
          />
        </AppsContent>

        <div className="apps-footer-table">
          <div>Total Data: {transactionList.pagination.totalData}</div>
          <AppsPagination
            pageSize={10}
            count={transactionList.pagination.totalData || 0}
            page={transactionList.pagination.page || 0}
            onChange={(page) => setParams({ page })}
            onShowSizeChange={(_, limit) => setParams({ limit })}
          />
        </div>
      </AppsContainer>
    </>
  );
};

export default TransactionList;
