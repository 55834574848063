import {
  CLEAR_USER_LIST,
  CLEAR_USER_DETAIL,
  GET_USER_LIST,
  GET_USER_DETAIL
} from "shared/constants/ActionTypes";

const initialState = {
  userList: {
    data: [],
    pagination: {},
  },
  userDetail: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_USER_LIST:
    return {
      ...state,
      userList: action.payload,
    };

  case GET_USER_DETAIL:
    return {
      ...state,
      userDetail: action.payload,
    };

  case CLEAR_USER_LIST:
    return {
      ...state,
      userList: { ...initialState.userList },
    };

  case CLEAR_USER_DETAIL:
    return {
      ...state,
      userDetail: {},
    };

  default:
    return state;
  }
};

export default userReducer;
