import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import IntlMessages from "@crema/utility/IntlMessages";

import { FiUsers } from "react-icons/fi";
import { GrStatusInfo } from "react-icons/gr";
import { MdOutlineCreateNewFolder } from "react-icons/md";

const OtherDetails = ({ data }) => {
  return (
    <div className="contact-detail-modal-item">
      <h6 className="contact-detail-modal-item-title">
        <IntlMessages id="common.otherDetails" />
      </h6>

      <div className="contact-detail-modal-item-content">
        <div className="contact-other-detail-item">
          <GrStatusInfo className="contact-other-detail-item-icon" />
          <div className="contact-other-detail-item-content">
            {data.isActive ? "AKTIF" : "TIDAK AKTIF"}
          </div>
        </div>

        <div className="contact-other-detail-item">
          <MdOutlineCreateNewFolder className="contact-other-detail-item-icon" />
          <div className="contact-other-detail-item-content">
            {moment(data.createdAt).format("YYYY-MM-DD")}
          </div>
        </div>

        <div className="contact-other-detail-item">
          <FiUsers className="contact-other-detail-item-icon" />
          <div className="contact-other-detail-item-content">{data.role}</div>
        </div>
      </div>
    </div>
  );
};

export default OtherDetails;

OtherDetails.propTypes = {
  data: PropTypes.object.isRequired,
};
