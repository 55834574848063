import {
  CLEAR_BRANCH_LIST,
  CLEAR_BRANCH_DETAIL,
  GET_BRANCH_LIST,
  GET_BRANCH_DETAIL
} from "shared/constants/ActionTypes";

const initialState = {
  branchList: {
    data: [],
    pagination: {},
  },
  branchDetail: {},
};

const branchReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_BRANCH_LIST:
    return {
      ...state,
      branchList: action.payload,
    };

  case GET_BRANCH_DETAIL:
    return {
      ...state,
      branchDetail: action.payload,
    };

  case CLEAR_BRANCH_LIST:
    return {
      ...state,
      branchList: { ...initialState.branchList },
    };

  case CLEAR_BRANCH_DETAIL:
    return {
      ...state,
      branchDetail: {},
    };

  default:
    return state;
  }
};

export default branchReducer;
