import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, Radio, Space } from "antd";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import AppCard from "@crema/core/AppCard";
import { AppRowContainer } from "@crema";
import {
  onCreateCategory,
  onEditCategory,
  onGetCategoryDetail
} from "redux/actions/Category";

const CategoryEdit = () => {
  const { loading } = useSelector(({ common }) => common);
  const { categoryDetail } = useSelector(({ category }) => category);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();

  useEffect(async () => {
    if (params.id) {
      dispatch(onGetCategoryDetail(params.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      const { name, address, isPublic } = categoryDetail; // ! isPublic tidak ada dari BE nya
      form.setFieldsValue({ name, address, isPublic });
    } else {
      form.setFieldsValue({ isPublic: true });
    }
  }, [categoryDetail]);

  const onFinish = async (values) => {
    try {
      if (params.id) {
        await dispatch(onEditCategory(params.id, values));
      } else {
        await dispatch(onCreateCategory(values));
        form.resetFields();
      }
      navigate("/categories");
    } catch (error) {
      //
    }
  };

  return (
    <>
      <AppPageMetadata title={`${params.id ? "Edit" : "Tambah"} Kategori`} />
      <AppsContainer
        title={`${params.id ? "Edit" : "Tambah"} Kategori`}
        fullView
        type="bottom"
      >
        <AppRowContainer>
          <Col span={24}>
            <AppCard title="Form">
              <Form
                onFinish={onFinish}
                form={form}
                className="edit-table-form"
                name="user"
                layout="vertical"
              >
                <Space direction="vertical" size={15} className="w-full">
                  <Form.Item
                    name="name"
                    label="Nama Kategori"
                    rules={[
                      { required: true, message: "Masukan nama kategori!" },
                    ]}
                  >
                    <Input placeholder="Nama Kategori" />
                  </Form.Item>

                  <Form.Item
                    name="description"
                    label="Deskripsi"
                  >
                    <Input.TextArea placeholder="Deskripsi" />
                  </Form.Item>

                  <Form.Item
                    name="isPublic"
                    label="Publish"
                    rules={[
                      { required: true, message: "Masukan publish !" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>YA</Radio>
                      <Radio value={false}>TIDAK</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      SIMPAN
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </AppCard>
          </Col>
        </AppRowContainer>
      </AppsContainer>
    </>
  );
};

export default CategoryEdit;
