import React from "react";
import PropTypes from "prop-types";
import AppTableContainer from "@crema/core/AppTableContainer";
import { Avatar } from "antd";
import "../index.style.less";

const MenuTable = ({ data }) => {
  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      render: ({ image, menuName, amount, price }) => (
        <div className='marketingUserInfo'>
          <Avatar src={image} />
          <div className='marketingUserInfoContent'>
            <h3>{menuName}</h3>
            <p>{amount}x {price}</p>
          </div>
        </div>
      ),
    },
    {
      title: "Catatan",
      dataIndex: "note",
      key: "note",
    },
  ];

  return (
    <AppTableContainer
      className='marketingCampaignTable'
      data={data}
      columns={columns}
    />
  );
};

export default MenuTable;

MenuTable.defaultProps = {
  data: [],
};

MenuTable.propTypes = {
  data: PropTypes.array,
};
