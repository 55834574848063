import axios from "services/axios";

export const categoryList = (params) =>
  axios.get("/categories", {
    params,
  });

export const categoryDetail = (id) => axios.get(`/categories/${id}`);

export const categoryCreate = (values) => axios.post("/categories", values);

export const categoryEdit = (id, values) => axios.patch(`/categories/${id}`, values);

export const categoryDelete = (id) => axios.delete(`/categories/${id}`);
