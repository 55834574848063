import React from "react";
import { Tabs } from "antd";
import { AppInfoView } from "@crema";
import { HiUser } from "react-icons/hi";
import PersonalInfo from "./PersonalInfo";

import "./index.style.less";

const { TabPane } = Tabs;

const UserProfile = () => {
  return (
    <div className="user-profile-container">
      <Tabs
        className="user-profile-tabs"
        defaultActiveKey="1"
        tabPosition="left"
      >
        <TabPane
          tab={
            <span className="user-profile-icon">
              <HiUser className="icon" />
              <span>
                Personal Info
              </span>
            </span>
          }
          key="1"
        >
          <PersonalInfo />
        </TabPane>
      </Tabs>

      <AppInfoView />
    </div>
  );
};

export default UserProfile;
