import React from "react";
import PropTypes from "prop-types";
import AppTableContainer from "@crema/core/AppTableContainer";
import moment from "moment";
import { RPFormat } from "utils/helpers";

const ReportComplimentTable = ({ data, loading, onEdit, onDetail, onDelete }) => {
  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => moment(value).format("YYYY-MM-DD"),
    },
    {
      title: "Customer",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Menu",
      dataIndex: "menuName",
      key: "menuName",
    },
    {
      title: "Qty",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Item Sales",
      dataIndex: "price",
      key: "price",
      render: (value) => RPFormat(value),
    },
  ];

  return (
    <AppTableContainer
      hoverColor
      data={data}
      columns={columns}
      loading={loading}
      scroll={{ x: "auto" }}
    />
  );
};

export default ReportComplimentTable;

ReportComplimentTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

ReportComplimentTable.defaultProps = {
  data: [],
  loading: false,
};
