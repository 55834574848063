import {
  CLEAR_MENU_LIST,
  CLEAR_MENU_DETAIL,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
  GET_MENU_LIST,
  GET_MENU_DETAIL
} from "shared/constants/ActionTypes";
import {
  menuCreate,
  menuDelete,
  menuDetail,
  menuEdit,
  menuList
} from "services/backend/menu";

// Clear states
export const onClearMenuList = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MENU_LIST });
  };
};

export const onClearMenuDetail = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_MENU_DETAIL });
  };
};

// Menu list
export const onGetMenuList = (params) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await menuList(params);

      if (response.status === 200) {
        const { data, pagination } = response.data.results;
        const payload = { data, pagination };

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_MENU_LIST, payload: payload });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

// Menu detail
export const onGetMenuDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await menuDetail(id);

      if (response.status === 200) {
        const { data } = response;
        const payload = data.results;

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_MENU_DETAIL, payload: payload });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

// Menu create
export const onCreateMenu = (values) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await menuCreate(values);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil membuat Menu baru!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};

// Menu edit
export const onEditMenu = (id, values) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await menuEdit(id, values);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil mengedit Menu!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      const { status } = error.response;

      if (status === 406) {
        dispatch({ type: FETCH_ERROR, payload: "Email sudah ada sebelumnya!" });
      } else {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      }
      return Promise.reject(error);
    }
  };
};

// User delete
export const onDeleteMenu = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await menuDelete(id);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil menghapus Menu!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};
