import React, { useEffect, useReducer, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, DatePicker, Select, Space, Form } from "antd";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import AppsHeader from "@crema/core/AppsContainer/AppsHeader";
import AppsContent from "@crema/core/AppsContainer/AppsContent";
import ReportComplimentTable from "./ReportComplimentTable";
import {
  onClearReportComplimentDetail,
  onClearReportComplimentList,
  onGetReportComplimentList
} from "redux/actions/ReportCompliment";
import {
  INITIAL_COMPLIMENT_PARAMS,
  INITIAL_PARAMS
} from "shared/constants/InitState";
import { reportCompliment } from "services/backend/report";
import moment from "moment";

const { Option } = Select;

const ReportCompliment = () => {
  const { loading } = useSelector(({ common }) => common);
  const { reportComplimentList } = useSelector(
    ({ reportCompliment }) => reportCompliment
  );
  const [params, setParams] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { ...INITIAL_PARAMS, ...INITIAL_COMPLIMENT_PARAMS }
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [downloadUrl, setDownloadUrl] = useState("");
  const [reportFilterType, setReportFilterType] = useState(params.type);
  const [filterForm] = Form.useForm();

  const typeMenus = ["DAILY", "MONTHLY", "YEARLY"];

  useEffect(() => {
    dispatch(onGetReportComplimentList(params));
  }, [dispatch, params]);

  useEffect(
    () => () => {
      dispatch(onClearReportComplimentList());
      dispatch(onClearReportComplimentDetail());
    },
    [dispatch]
  );

  const onFinishFilter = async (values) => {
    const date = new Date(values.date);
    const dateYear = date.getFullYear();
    const dateMonth = date.getMonth() + 1;
    const dateNumber = date.getDate();
    setParams({
      type: reportFilterType,
      date: `${dateYear}-${dateMonth}-${dateNumber}`,
    });
  };

  const handleDownloadExcel = useCallback(async () => {
    const excelParams = {
      ...params,
      date: moment(params.date).format("YYYY-MM-DD"),
      limit: undefined,
      page: undefined,
      isDownload: true,
    };

    const response = await dispatch(onGetReportComplimentList(excelParams));
    console.log(response);
    // let urlString = response.request.responseURL;
    // const urlParams = new URLSearchParams(urlString);
    // urlParams.set("isDownload", "true");
    // urlString = urlParams.toString();
    // setDownloadUrl(decodeURIComponent(urlString));
    // setData(response.data.results.data);

    // console.log(response);
    // console.log(decodeURIComponent(urlString));
  }, [params, reportCompliment, dispatch, onGetReportComplimentList]);

  return (
    <>
      <AppPageMetadata title="Report Compliment" />

      <AppsContainer title="Report Compliment" fullView type="bottom">
        <AppsHeader key="wrap">
          <div className="table-header">
            <Form
              onFinish={onFinishFilter}
              form={filterForm}
              className="edit-table-form"
              name="reportCompliment"
              layout="vertical"
              initialValues={{
                type: "DAILY",
                date: moment(),
                isDownload: false,
              }}
            >
              <Space size={15}>
                <Form.Item name="type">
                  <Select
                    placeholder="Pilih Tipe"
                    size=""
                    onChange={(val) => setReportFilterType(val)}
                  >
                    {typeMenus.map((menu, indexMenu) => (
                      <Option key={indexMenu} value={menu}>
                        {menu}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item name="date">
                  <DatePicker
                    placeholder="Pilih Tanggal"
                    size="large"
                    picker={
                      reportFilterType === "YEARLY"
                        ? "year"
                        : reportFilterType === "MONTHLY"
                          ? "month"
                          : "date"
                    }
                  />
                </Form.Item>

                <Button htmlType="submit" type="primary">
                  Pilih
                </Button>
                <Button htmlType="button" type="danger" onClick={() => {}}>
                  Reset
                </Button>
              </Space>
            </Form>

            <div className="table-header-right">
              {/* <Button type="primary" href={downloadUrl} download={true}>
                Download aja
              </Button> */}
              <Button onClick={handleDownloadExcel} type="primary">
                Download Excel
              </Button>
            </div>
          </div>
        </AppsHeader>

        <AppsContent
          key={"wrap1"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <ReportComplimentTable
            loading={loading}
            data={reportComplimentList.data}
          />
        </AppsContent>
      </AppsContainer>
    </>
  );
};

export default ReportCompliment;
