import {
  CLEAR_CATEGORY_LIST,
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
  GET_CATEGORY_LIST,
  GET_CATEGORY_DETAIL
} from "shared/constants/ActionTypes";
import {
  categoryCreate,
  categoryDelete,
  categoryDetail,
  categoryEdit,
  categoryList
} from "services/backend/category";

export const onClearCategoryList = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CATEGORY_LIST });
  };
};

export const onGetCategoryList = (params) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await categoryList(params);

      if (response.status === 200) {
        const { data, pagination } = response.data.results;
        const payload = { data, pagination };

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_CATEGORY_LIST, payload: payload });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

// User detail
export const onGetCategoryDetail = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await categoryDetail(id);

      if (response.status === 200) {
        const { data } = response;
        const payload = data.results;

        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: GET_CATEGORY_DETAIL, payload: payload });
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
    }
  };
};

// User create
export const onCreateCategory = (values) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await categoryCreate(values);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil membuat kategori menu baru!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};

// User Edit
export const onEditCategory = (id, values) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await categoryEdit(id, values);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil mengedit kategori menu!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};

// User delete
export const onDeleteCategory = (id) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await categoryDelete(id);

      if (response.status === 200) {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({
          type: SHOW_MESSAGE,
          payload: "Berhasil menghapus kategori menu!",
        });
        return Promise.resolve(response);
      } else {
        dispatch({
          type: FETCH_ERROR,
          payload: "Terjadi kesalahan, Silahkan coba lagi!",
        });
        return Promise.reject(response);
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return Promise.reject(error);
    }
  };
};
