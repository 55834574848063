import React from "react";
import { Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppInfoView } from "@crema";
import { onLogin } from "redux/actions/Auth";
import { setLogin } from "utils/helpers";

const SignIn = () => {
  const { loading } = useSelector(({ common }) => common);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      const { accessToken, userData, success } = await dispatch(onLogin(values));

      if (success) {
        setLogin(accessToken, userData, () => {
          window.location.href = "/users";
        });
      }
    } catch (error) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="sign">
      <div className="sign-content">
        <Form
          className="sign-form"
          name="login"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="user"
            className="form-field"
            rules={[
              {
                required: true,
                message: "Masukan Email/Username/No.Handphone Anda!",
              },
            ]}
          >
            <Input placeholder="Email/Username/Nomer Handphone" />
          </Form.Item>

          <Form.Item
            name="password"
            className="form-field"
            rules={[{ required: true, message: "Masukan Password Anda!" }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <div className="form-btn-field">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="sign-btn"
            >
              LOGIN
            </Button>
          </div>
        </Form>
      </div>

      <AppInfoView />
    </div>
  );
};

export default SignIn;
