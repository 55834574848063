import {
  CLEAR_MENU_LIST,
  CLEAR_MENU_DETAIL,
  GET_MENU_LIST,
  GET_MENU_DETAIL
} from "shared/constants/ActionTypes";

const initialState = {
  menuList: {
    data: [],
    pagination: {},
  },
  menuDetail: {},
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_MENU_LIST:
    return {
      ...state,
      menuList: action.payload,
    };

  case GET_MENU_DETAIL:
    return {
      ...state,
      menuDetail: action.payload,
    };

  case CLEAR_MENU_LIST:
    return {
      ...state,
      menuList: { ...initialState.menuList },
    };

  case CLEAR_MENU_DETAIL:
    return {
      ...state,
      menuDetail: {},
    };

  default:
    return state;
  }
};

export default menuReducer;
