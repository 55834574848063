import React from "react";
import { Pagination } from "antd";
import PropTypes from "prop-types";

const AppsPagination = ({
  count,
  page,
  onChange,
  onShowSizeChange,
  pageSize,
  className,
}) => {
  return (
    <Pagination
      size="small"
      onChange={onChange}
      onShowSizeChange={onShowSizeChange}
      defaultPageSize={10}
      component="div"
      current={page}
      total={count}
      pageSize={pageSize}
      className={className}
      page={page}
      backIconButtonProps={{ "aria-label": "Previous Page" }}
      nextIconButtonProps={{ "aria-label": "Next Page" }}
      rowsPerPageOptions={[]}
    />
  );
};

export default AppsPagination;

AppsPagination.defaultProps = {
  className: "",
  pageSize: 15,
};

AppsPagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onShowSizeChange: PropTypes.func,
  className: PropTypes.string,
  pageSize: PropTypes.number,
};
