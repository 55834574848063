import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS
} from "../../shared/constants/ActionTypes";
import { login } from "services/backend/auth";

export const onLogin = (formData) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_START });
    try {
      const { status, data } = await login(formData);
      if (status === 200) {
        dispatch({ type: FETCH_SUCCESS });
      } else {
        dispatch({ type: FETCH_ERROR, payload: "Something went wrong !" });
      }
      return Promise.resolve(data);
    } catch (error) {
      const { message } = error.response.data;
      dispatch({ type: FETCH_ERROR, payload: message });
      return Promise.reject(error);
    }
  };
};
