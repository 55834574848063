import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Col, Avatar, Modal, Button } from "antd";
import AppScrollbar from "@crema/core/AppScrollbar";
import { AppRowContainer } from "@crema";
import PersonalDetails from "./PersonalDetail";
import OtherDetails from "./OtherDetails";

import "./index.style.less";

const UserDetail = ({ visible, onClose }) => {
  const { userDetail } = useSelector(({ user }) => user);

  const onCloseModal = () => {
    onClose(false);
  };

  return (
    <>
      <Modal
        visible={visible}
        onOk={visible}
        footer={false}
        onCancel={onCloseModal}
        className="contact-detail-modal"
      >
        <div className="contact-detail-modal-header">
          <div className="contact-detail-modal-user">
            <Avatar
              className="contact-detail-modal-user-avatar"
              src={userDetail.avatar}
            />
            <h4>{userDetail.name}</h4>
          </div>
        </div>

        <AppScrollbar className="contact-detail-modal-scrollbar">
          <div className="contact-detail-modal-content">
            <AppRowContainer>
              <Col item xs={24} md={12}>
                <PersonalDetails data={userDetail} />
              </Col>
              <Col item xs={24} md={12}>
                <OtherDetails data={userDetail} />
              </Col>
            </AppRowContainer>
          </div>

          <div className="contact-form-footer">
            <Button
              type="primary"
              ghost
              className="contact-form-btn"
              onClick={onCloseModal}
            >
              Tutup
            </Button>
          </div>
        </AppScrollbar>
      </Modal>
    </>
  );
};

export default UserDetail;

UserDetail.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
