import axios from "services/axios";

export const menuList = (params) =>
  axios.get("/menu", {
    params,
  });

export const menuDetail = (id) => axios.get(`/menu/${id}`);

export const menuEdit = (id, values) => axios.patch(`/menu/${id}`, values, {
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const menuCreate = (values) => axios.post("/menu", values, {
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

export const menuDelete = (id) => axios.delete(`/menu/${id}`);
