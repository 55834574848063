import React from "react";
import PropTypes from "prop-types";
import { Avatar, Badge } from "antd";
import AppTableContainer from "@crema/core/AppTableContainer";
import { OrderActions } from "components/organisms";
import { RPFormat } from "utils/helpers";

const TransactionTable = ({ data, loading, onDetail }) => {
  const columns = [
    {
      title: "Nama Pelanggan",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Nomor Meja",
      dataIndex: "tableNo",
      key: "tableNo",
    },
    {
      title: "Metode Pembayaran",
      dataIndex: "payment",
      key: "payment",
    },
    {
      title: "Status Pembayaran",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (isPaid) => isPaid ? "Sudah Bayar" : "Belum Bayar",
    },
    {
      title: "Status Transaksi",
      dataIndex: "",
      key: "",
    },
    {
      title: "Harga",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (price) => RPFormat(price),
    },
    {
      title: "Aksi",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (_, { id }) => (
        <OrderActions
          onDetail={() => onDetail(id)}
        />
      ),
    },
  ];

  return (
    <AppTableContainer
      hoverColor
      data={data}
      columns={columns}
      loading={loading}
    />
  );
};

export default TransactionTable;

TransactionTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

TransactionTable.defaultProps = {
  data: [],
  loading: false,
};
