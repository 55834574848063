import axios from "services/axios";

export const login = (formData) => axios.post("/auth/login", formData);

export const userList = (params) =>
  axios.get("/users", {
    params,
  });

export const userDetail = (id) => axios.get(`/users/${id}`);

export const userCreate = (values) => axios.post("/users", values);

export const userEdit = (id, values) => axios.patch(`/users/${id}`, values);

export const userDelete = (id) => axios.delete(`/users/${id}`);
