import React from "react";
import PropTypes from "prop-types";
import AppTableContainer from "@crema/core/AppTableContainer";

const ReportComplimentTable = ({ data, loading, onEdit, onDetail, onDelete }) => {
  const columns = [
    {
      title: "MENU ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "MENU NAME",
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      title: "SOLD",
      dataIndex: "itemSold",
      key: "itemSold",
      render: (_, { itemSold }) => parseInt(itemSold || 0),
    },
    {
      title: "SALES",
      dataIndex: "itemSales",
      key: "itemSales",
      render: (_, { itemSales }) => parseInt(itemSales || 0),
    },
    {
      title: "TAX",
      dataIndex: "tax",
      key: "tax",
      render: (_, { tax }) => parseInt(tax || 0),
    },
    {
      title: "TOTAL",
      dataIndex: "price",
      key: "price",
      render: (_, { tax, itemSales }) => parseInt(tax || 0) + parseInt(itemSales || 0),
    },
  ];

  return (
    <AppTableContainer
      hoverColor
      data={data}
      columns={columns}
      loading={loading}
      scroll={{ x: "auto" }}
    />
  );
};

export default ReportComplimentTable;

ReportComplimentTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

ReportComplimentTable.defaultProps = {
  data: [],
  loading: false,
};
