import axios from "axios";
import { getToken } from "utils/helpers";
import errorResponseHandler from "./errorResponseHandler";
const MAIN_HOST = process.env.REACT_APP_DASHBOARD_PRODUCTION_API;

const instance = axios.create({
  baseURL: MAIN_HOST,
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken();

    if (token) {
      const bearerToken = `Bearer ${token}`;
      config.headers.Authorization = bearerToken;
    }
    return config;
  },
  (error) => {
    return error;
  }
);

instance.interceptors.response.use(
  (response) => response,
  errorResponseHandler
);

export default instance;
