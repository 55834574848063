import React from "react";
import PropTypes from "prop-types";
import { Card, Image } from "antd";
import AppAnimateGroup from "../../@crema/core/AppAnimateGroup";
import "./AuthWrapper.style.less";
// import {AppInfoView} from '../../@crema';

const AuthWrapper = ({ children }) => {
  return (
    <AppAnimateGroup
      type="scale"
      animateStyle={{ flex: 1 }}
      delay={0}
      interval={10}
      duration={200}
    >
      <div className="auth-wrap" key={"wrap"}>
        <Card className="auth-card">
          <div className="auth-main-content">
            <div className="auth-card-header">
              <div className="app-logo">
                <Image
                  width={75}
                  height="auto"
                  src="/assets/images/rooftop-256.png"
                  alt="crema-logo"
                  preview={false}
                />
              </div>
            </div>
            {children}
          </div>
          <div className="auth-wel-action">
            <div className="auth-wel-content">
              <h2>Selamat datang di Dashboard Rooftop Coffee!</h2>
            </div>
          </div>
        </Card>
      </div>
      {/* <AppInfoView /> */}
    </AppAnimateGroup>
  );
};

export default AuthWrapper;

AuthWrapper.propTypes = {
  children: PropTypes.node,
};
