import React, { useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, DatePicker, Select, Space, Form } from "antd";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import AppsHeader from "@crema/core/AppsContainer/AppsHeader";
import AppsContent from "@crema/core/AppsContainer/AppsContent";
import ReportSalesTable from "./ReportSalesTable";
import { INITIAL_PARAMS } from "shared/constants/InitState";
import {
  reportSales
} from "services/backend/report";

const { Option } = Select;

const ReportSales = () => {
  const { loading } = useSelector(({ common }) => common);
  const { userList } = useSelector(({ user }) => user);
  const [params, setParams] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    INITIAL_PARAMS
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [reportFilterType, setReportFilterType] = useState("");
  const [reportFilterDate, setReportFilterDate] = useState("");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  const typeMenus = ["DAILY", "MONTHLY", "YEARLY"];

  const getCompliment = async (isDownload) => {
    if (reportFilterType && reportFilterDate) {
      setIsLoading(true);
      try {
        const date = new Date(reportFilterDate);
        const dateYear = date.getFullYear();
        const dateMonth = date.getMonth() + 1;
        const dateNumber = date.getDate();
        const params = {
          type: reportFilterType,
          date: `${dateYear}-${dateMonth}-${dateNumber}`,
          isDownload: !!isDownload,
        };

        const response = await reportSales(params);

        let urlString = response.request.responseURL;
        const urlParams = new URLSearchParams(urlString);
        urlParams.set("isDownload", "true");
        urlString = urlParams.toString();
        setDownloadUrl(decodeURIComponent(urlString));
        setData(response.data.results.data);
      } catch (error) {
        alert("SOMETHING WRONG");
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("YOU HAVE TO CHOOSE TYPE AND DATE");
    }
  };

  return (
    <>
      <AppPageMetadata title="Report Sales" />

      <AppsContainer title="Report Sales" fullView type="bottom">
        <AppsHeader key="wrap">
          <div className="table-header">
            <Space size={15}>
              <Select placeholder="Pilih Tipe" size="" onChange={(e) => setReportFilterType(e)}>
                {typeMenus.map((menu, indexMenu) => (
                  <Option key={indexMenu} value={menu}>{menu}</Option>
                ))}
              </Select>

              <DatePicker
                placeholder="Pilih Tanggal"
                size="large"
                onChange={(value) => setReportFilterDate(value)}
                picker={
                  reportFilterType === "YEARLY"
                    ? "year"
                    : reportFilterType === "MONTHLY"
                      ? "month"
                      : "date"
                }
              />

              <Button type="primary" onClick={() => getCompliment(false)}>
                Search
              </Button>
              <Button type="danger" onClick={() => { }}>
                Reset
              </Button>
            </Space>

            <div className="table-header-right">
              <Button type="primary" href={downloadUrl} download={true} disabled={!downloadUrl}>
                Download Excel
              </Button>
            </div>
          </div>
        </AppsHeader>

        <AppsContent
          key={"wrap1"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <ReportSalesTable
            loading={isLoading}
            data={data}
          />
        </AppsContent>
      </AppsContainer>
    </>
  );
};

export default ReportSales;
