import React, { useEffect, useState } from "react";
import { AppRowContainer } from "@crema";
import AppCard from "@crema/core/AppCard";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import { Button, Col, Form, Image, Input, Radio, Select, Space, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { onClearCategoryList, onGetCategoryList } from "redux/actions/Category";
import { onClearMenuDetail, onCreateMenu, onEditMenu, onGetMenuDetail } from "redux/actions/Menu";

const { Option } = Select;

const MenuEdit = () => {
  const { loading } = useSelector(({ common }) => common);
  const { menuDetail } = useSelector(({ menu }) => menu);
  const { categoryList } = useSelector(({ category }) => category);
  const [thumbnail, setThumbnail] = useState(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();

  useEffect(async () => {
    if (params.id) {
      dispatch(onGetMenuDetail(params.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      const {
        name,
        thumbnail,
        status,
        type,
        categories,
        description,
        sellingPrice,
        stock,
        is_employee_menu: isEmployeeMenu,
      } = menuDetail;

      const newValues = {
        name,
        thumbnail,
        status,
        type,
        categories: categories?.map(({ id }) => id),
        description,
        sellingPrice,
        stock,
        is_employee_menu: isEmployeeMenu,
      };

      form.setFieldsValue(newValues);
    } else {
      form.setFieldsValue({ is_employee_menu: false });
    }
  }, [menuDetail]);

  useEffect(() => {
    dispatch(onGetCategoryList());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(onClearMenuDetail());
      dispatch(onClearCategoryList());
    };
  }, [dispatch]);

  const onChangeThumbnail = ({ fileList: newFileList }) => {
    setThumbnail(newFileList[0]?.originFileObj);
  };

  const onFinish = async (values) => {
    try {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("status", values.status);
      formData.append("type", values.type);
      formData.append("originalPrice", values.originalPrice || "0");
      formData.append("sellingPrice", values.sellingPrice || "0");
      formData.append("categories", JSON.stringify(values.categories));
      formData.append("description", values.description || "");
      formData.append("isPublic", true); // ! SEMENTARA TRUE DULU
      formData.append("isEmployeeMenu", values.is_employee_menu);

      if (params.id) {
        // if (thumbnail) { // ! ADA MASALAH S3 !
        //   formData.append("thumbnail", thumbnail);
        // }
        await dispatch(onEditMenu(params.id, formData));
      } else {
        // formData.append("thumbnail", thumbnail || "");
        await dispatch(onCreateMenu(formData));
        form.resetFields();
      }
      navigate("/menus");
    } catch (error) {
      //
    }
  };

  return (
    <>
      <AppPageMetadata title="Dashboard" />
      <AppsContainer
        title={`${params.id ? "Edit" : "Tambah"} Menu`}
        fullView
        type="bottom"
      >
        <AppRowContainer>
          <Col span={24}>
            <AppCard title="Form">
              <Form
                onFinish={onFinish}
                form={form}
                className="edit-table-form"
                name="menu"
                layout="vertical"
              >
                <Space direction="vertical" size={15} className="w-full">
                  <Space direction="vertical">
                    <div>Thumbnail</div>
                    <Space size={12} align="center">
                      {menuDetail.thumbnail && (
                        <Space direction="vertical">
                          <Image src={menuDetail.thumbnail} width={100} />
                        </Space>
                      )}
                      <ImgCrop rotate aspect={1 / 1}>
                        <Upload
                          onChange={onChangeThumbnail}
                          action="/upload.do"
                          listType="picture-card"
                          maxCount={1}
                        >
                          Upload
                        </Upload>
                      </ImgCrop>
                    </Space>
                    {params.id && (
                      <small className="bg-primary">* KOSONGKAN JIKA TIDAK INGIN DIUBAH</small>
                    )}
                  </Space>

                  <Form.Item
                    name="name"
                    label="Nama Menu"
                    rules={[
                      {
                        required: true,
                        message: "Masukan nama menu!",
                      },
                      {
                        max: 30,
                        message: "Nama menu maksimal 30 karakter",
                      },
                      {
                        pattern: /^[^`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]*$/gi,
                        message: "Nama menu tidak boleh menggunakan simbol",
                      },
                    ]}
                  >
                    <Input placeholder="Nama menu" />
                  </Form.Item>

                  <Form.Item
                    name="status"
                    label="Status"
                    rules={[
                      { required: true, message: "Masukan status menu!" },
                    ]}
                  >
                    <Select placeholder="-- Pilih --">
                      <Option value="publish">Publish</Option>
                      <Option value="draft">Draft</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="type"
                    label="Tipe"
                    rules={[{ required: true, message: "Masukan tipe menu!" }]}
                  >
                    <Select placeholder="-- Pilih --">
                      <Option value="kitchen">Kitchen</Option>
                      <Option value="bar">Bar</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="categories"
                    label="Kategori"
                    rules={[
                      { required: true, message: "Masukan kategori menu!" },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="-- Pilih --"
                    >
                      {categoryList.data.map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="is_employee_menu"
                    label="Menu untuk Karyawan"
                    rules={[
                      { required: true, message: "Masukan Public !" },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={true}>YA</Radio>
                      <Radio value={false}>TIDAK</Radio>
                    </Radio.Group>
                  </Form.Item>

                  {/* <Form.Item // ! HIDE SEMENTARA
                      name={`originalPrice-${id}`}
                      label="Harga Asli"
                      rules={[
                        { required: true, message: "Masukan harga asli menu!" },
                      ]}
                    >
                      <Input placeholder="Harga Asli" />
                    </Form.Item> */}

                  <Form.Item
                    name="sellingPrice"
                    label="Harga Jual"
                    rules={[
                      { required: true, message: "Masukan harga jual menu!" },
                    ]}
                  >
                    <Input placeholder="Harga Jual" />
                  </Form.Item>

                  {/* <Form.Item  // ! HIDE SEMENTARA
                      name="isPublic"
                      label="Public"
                      rules={[
                        { required: true, message: "Masukan Public !" },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>YA</Radio>
                        <Radio value={false}>TIDAK</Radio>
                      </Radio.Group>
                    </Form.Item> */}

                  <Form.Item
                    name="stock"
                    label="Stok"
                    rules={[
                      { required: true, message: "Masukan stok!" },
                    ]}
                  >
                    <Input placeholder="Stok" />
                  </Form.Item>

                  <Form.Item name="description" label="Deskripsi">
                    <Input.TextArea placeholder="Deskripsi" />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      SIMPAN
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </AppCard>
          </Col>
        </AppRowContainer>
      </AppsContainer>
    </>
  );
};

export default MenuEdit;
