import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "antd";
import AppTableContainer from "@crema/core/AppTableContainer";
import { OrderActions } from "components/organisms";

const CategoryTable = ({ data, loading, onEdit, onDelete }) => {
  const columns = [
    {
      title: "Gambar",
      dataIndex: "image",
      key: "image",
      render: (text) => <Avatar src={text} />,
    },
    {
      title: "Nama Kategori",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Aksi",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (_, { id }) => (
        <OrderActions
          onEdit={() => onEdit(id)}
          onDelete={() => onDelete(id)}
        />
      ),
    },
  ];

  return (
    <AppTableContainer
      hoverColor
      data={data}
      columns={columns}
      loading={loading}
    />
  );
};

export default CategoryTable;

CategoryTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onDetail: PropTypes.func,
};

CategoryTable.defaultProps = {
  data: [],
  loading: false,
};
