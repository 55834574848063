import React, { useEffect, useState } from "react";
import { AppRowContainer } from "@crema";
import AppCard from "@crema/core/AppCard";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import { Button, Card, Col, Form, Input, Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { onClearMenuList, onGetMenuList } from "redux/actions/Menu";
import { onClearPackageDetail, onCreatePackage, onEditPackage, onGetPackageDetail } from "redux/actions/Package";

const { Option } = Select;

const PackageEdit = () => {
  const { loading } = useSelector(({ common }) => common);
  const { menuList } = useSelector(({ menu }) => menu);
  const [menuCounting, setMenuCounting] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();

  useEffect(async () => {
    if (params.id) {
      dispatch(onGetPackageDetail(params.id));
    }
  }, [dispatch]);

  // useEffect(() => {
  //   if (params.id) {
  //     const {
  //       name,
  //       thumbnail,
  //       status,
  //       branch,
  //       description,
  //       originalPrice,
  //       price,
  //     } = menuDetail;
  //     form.setFieldsValue({
  //       name,
  //       thumbnail,
  //       status,
  //       branch: branch?.map(({ branchId }) => branchId),
  //       description,
  //       originalPrice,
  //       price,
  //     });
  //   }
  // }, [menuDetail]);

  useEffect(() => {
    dispatch(onGetMenuList({ limit: 9999 }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(onClearPackageDetail());
      dispatch(onClearMenuList());
    };
  }, [dispatch]);

  // const onChangeThumbnail = ({ fileList: newFileList }) => {
  //   setThumbnail(newFileList[0]?.originFileObj);
  // };

  const onFinish = async (values) => {
    const { name, menu, status, description, originalPrice, price } = values;
    const menuAmount = menu.map(dataMenu => {
      const menuId = dataMenu.split("-/-")[0];
      const amount = values[`menuCount-/-${menuId}`];
      return {
        menuId,
        amount,
      };
    });

    const formData = {
      name,
      status,
      originalPrice,
      price,
      menu: menuAmount,
      description: description || "",
    };

    try {
      if (params.id) {
        // if (thumbnail) {
        //   formData.append("thumbnail", thumbnail);
        // }
        await dispatch(onEditPackage(params.id, formData));
      } else {
        // formData.append("thumbnail", thumbnail || "");
        await dispatch(onCreatePackage(formData));
        form.resetFields();
      }
      navigate("/packages");
    } catch (error) {
      //
    }
  };

  const onChangeMenus = (values) => {
    setMenuCounting(values);
  };

  return (
    <>
      <AppPageMetadata title="Dashboard" />
      <AppsContainer
        title={`${params.id ? "Edit" : "Tambah"} Menu Paket`}
        fullView
        type="bottom"
      >
        <AppRowContainer>
          <Col span={24}>
            <AppCard title="Form">
              <Form
                onFinish={onFinish}
                form={form}
                className="edit-table-form"
                name="package"
                layout="vertical"
              >
                <Space direction="vertical" size={15} className="w-full">
                  <Form.Item
                    name="name"
                    label="Nama Menu Paket"
                    rules={[
                      {
                        required: true,
                        message: "Masukan nama menu paket!",
                      },
                      {
                        max: 30,
                        message: "Nama menu paket maksimal 30 karakter",
                      },
                      {
                        pattern: /^[^`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]*$/gi,
                        message: "Nama menu paket tidak boleh menggunakan simbol",
                      },
                    ]}
                  >
                    <Input placeholder="Nama menu paket" />
                  </Form.Item>

                  <Card>
                    <Form.Item
                      name="menu"
                      label="Menu"
                      rules={[
                        { required: true, message: "Masukan menu!" },
                      ]}
                    >
                      <Select
                        onChange={onChangeMenus}
                        placeholder="-- Pilih --"
                        mode="multiple"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        showSearch
                        allowClear
                      >
                        {menuList.data.map(({ id, name }, menuIndex) => (
                          <Option key={menuIndex} value={`${id}-/-${name}`}>{name}</Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {menuCounting.map((menu, menuIndex) => {
                      const menuId = menu.split("-/-")[0];
                      const menuName = menu.split("-/-").pop();
                      return (
                        <Form.Item
                          key={menuIndex}
                          name={`menuCount-/-${menuId}`}
                          label={`Jumlah - ${menuName}`}
                          rules={[
                            {
                              required: true,
                              message: "Masukan jumlah menu",
                            },
                          ]}
                        >
                          <Input placeholder="Jumlah menu" />
                        </Form.Item>
                      );
                    })}
                  </Card>

                  <Form.Item
                    name="status"
                    label="Status"
                    rules={[
                      { required: true, message: "Masukan status menu!" },
                    ]}
                  >
                    <Select placeholder="-- Pilih --">
                      <Option value="publish">Publish</Option>
                      <Option value="draft">Draft</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="originalPrice"
                    label="Harga Asli"
                    rules={[
                      { required: true, message: "Masukan harga asli menu!" },
                    ]}
                  >
                    <Input placeholder="Harga asli" />
                  </Form.Item>

                  <Form.Item
                    name="price"
                    label="Harga Jual"
                    rules={[
                      { required: true, message: "Masukan harga jual menu!" },
                    ]}
                  >
                    <Input placeholder="Harga jual" />
                  </Form.Item>

                  <Form.Item name="description" label="Deskripsi">
                    <Input.TextArea placeholder="Deskripsi" />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      SIMPAN
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </AppCard>
          </Col>
        </AppRowContainer>
      </AppsContainer>
    </>
  );
};

export default PackageEdit;
