import React from "react";
import PropTypes from "prop-types";
import { Button, Menu, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";

const OrderActions = ({ onDetail, onEdit, onDelete }) => {
  const menu = (
    <Menu>
      {onDetail && (
        <Menu.Item onClick={onDetail} key={1} style={{ fontSize: 14 }}>
          Detail
        </Menu.Item>
      )}
      {onEdit && (
        <Menu.Item onClick={onEdit} key={2} style={{ fontSize: 14 }}>
          Edit
        </Menu.Item>
      )}
      {onDelete && (
        <Menu.Item onClick={onDelete} key={3} style={{ fontSize: 14 }}>
          Delete
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="circle">
          <MoreOutlined />
        </Button>
      </Dropdown>
    </>
  );
};

export default OrderActions;

OrderActions.propTypes = {
  onDetail: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};
