import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Form, Col, Input, Button, Select } from "antd";
import { useDropzone } from "react-dropzone";
import { AppRowContainer } from "@crema";
import { onEditUser, onGetUserDetail } from "redux/actions/User";
import { userData } from "utils/helpers";
import { onGetBranchList } from "redux/actions/Branch";

import "./index.style.less";

const PersonalInfo = () => {
  const imagePlaceholder = "/assets/images/placeholder.jpg";

  const { loading } = useSelector(({ common }) => common);
  const { userDetail } = useSelector(({ user }) => user);
  const { branchList } = useSelector(({ branch }) => branch);
  const [userImage, setUserImage] = useState(imagePlaceholder);
  const [disableForm, setDisableForm] = useState(true);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { id: userId } = userData();

  useEffect(() => {
    dispatch(onGetUserDetail(userId));
    dispatch(onGetBranchList());
  }, [dispatch]);

  useEffect(() => {
    const { name, email, avatar } = userDetail;
    const values = { name, email };
    form.setFieldsValue(values);
    setUserImage(avatar);
  }, [form, userDetail]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setUserImage(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const onReset = () => {
    setUserImage("/assets/images/placeholder.jpg");
  };

  const onFinish = async (values) => {
    values.isActive = true; // ! isActice di hardcode sementara karna dari BE tidak otomatis

    await dispatch(onEditUser(userId, values));
    setDisableForm(true);
    form.setFieldsValue({
      password: "",
      confirmPassword: "",
    });
  };

  const onSubmit = () => {
    setDisableForm(false);
  };

  const onCancelEdit = () => {
    setDisableForm(true);
  };

  return (
    <Form className="info" form={form} onFinish={onFinish}>
      <Form.Item className="info-upload">
        <Avatar className="info-upload-avatar" src={userImage} />
        <div className="info-upload-content">
          <div className="info-upload-btn-group">
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <label htmlFor="icon-button-file">
                <Button type="primary">Upload</Button>
              </label>
            </div>
            <Button onClick={onReset}>Reset</Button>
          </div>
          <p>Harus berupa file JPG, GIF atau PNG. Dengan maksimal ukuran 800kB</p>
        </div>
      </Form.Item>
      <AppRowContainer gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: "Masukan nama lengkap Anda!" },
            ]}
          >
            <Input placeholder="Nama lengkap" readOnly={disableForm} />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            name="branchId"
            rules={[
              { required: true, message: "Masukan cabang Anda!" },
            ]}
          >
            <Select placeholder="-- Pilih --" disabled={disableForm}>
              {branchList.data.map(({ id, name }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Masukan email Anda!" },
              { type: "email", message: "Email tidak valid!" },
            ]}
          >
            <Input placeholder="Email" readOnly={disableForm} />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            name="password"
            rules={[
              { min: 8, message: "Password minimal 8 karakter!" },
            ]}
          >
            <Input type="password" placeholder="Password" readOnly={disableForm} />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            name="confirmPassword"
            rules={[
              ({ getFieldValue }) => ({
                validator (_, value) {
                  const password = getFieldValue("password");

                  if (!value || password === value) {
                    return Promise.resolve();
                  }
                  if (password.length > 1 && !value) {
                    return Promise.reject(
                      new Error("Masukan konfirmasi password Anda!")
                    );
                  }
                  return Promise.reject(
                    new Error("Password harus sama!")
                  );
                },
              }),
            ]}
          >
            <Input type="password" placeholder="Konfirmasi Password" readOnly={disableForm} />
          </Form.Item>
        </Col>

        <Col xs={24} md={24}>
          <Form.Item shouldUpdate className="user-profile-group-btn">
            <Button onClick={onSubmit} type="primary" htmlType="submit" loading={loading}>
              {disableForm ? "UBAH" : "SIMPAN"}
            </Button>
            {!disableForm && (
              <Button onClick={onCancelEdit}>Batal</Button>
            )}
          </Form.Item>
        </Col>
      </AppRowContainer>
    </Form>
  );
};

export default PersonalInfo;
