import React, { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Modal } from "antd";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import AppsHeader from "@crema/core/AppsContainer/AppsHeader";
import AppsPagination from "@crema/core/AppsPagination";
import AppsContent from "@crema/core/AppsContainer/AppsContent";
import MenuTable from "./MenuTable";
import { onClearMenuList, onDeleteMenu, onGetMenuList } from "redux/actions/Menu";
import { INITIAL_PARAMS } from "shared/constants/InitState";

const MenuList = () => {
  const { loading } = useSelector(({ common }) => common);
  const { menuList } = useSelector(({ menu }) => menu);
  const [params, setParams] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    INITIAL_PARAMS
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(onGetMenuList(params));
  }, [dispatch, params]);

  useEffect(
    () => () => {
      dispatch(onClearMenuList());
    },
    [dispatch]
  );

  const onDeleteConfirm = (id) => {
    Modal.confirm({
      title: "HAPUS?",
      content: "Apakah Anda yakin ingin menghapus data tersebut?",
      okText: "Hapus",
      cancelText: "Batal",
      onOk: async () => {
        await dispatch(onDeleteMenu(id));
        dispatch(onGetMenuList());
      },
    });
  };

  return (
    <>
      <AppPageMetadata title="Daftar Menu" />
      <AppsContainer title="Daftar Menu" fullView type="bottom">
        <AppsHeader key="wrap">
          <div className="table-header">
            <div className="table-header-input-view">
              <Input
                onChange={({ target }) => setParams({ search: target.value, page: 1 })}
                id="name"
                placeholder="Search"
                type="search"
                name="name"
              />
            </div>
            <div className="table-header-right">
              <Button type="primary" onClick={() => navigate("/menus/create")}>
                Tambah Menu
              </Button>
            </div>
          </div>
        </AppsHeader>

        <AppsContent
          key={"wrap1"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <MenuTable
            loading={loading}
            data={menuList.data}
            onDetail={(id) => navigate(`/menus/${id}/detail`)}
            onEdit={(id) => navigate(`/menus/${id}/edit`)}
            onDelete={(id) => onDeleteConfirm(id)}
          />
        </AppsContent>

        <div className="apps-footer-table">
          <div>Total Data: {menuList.pagination.totalData}</div>
          <AppsPagination
            pageSize={10}
            count={menuList.pagination.totalData || 0}
            page={menuList.pagination.page || 0}
            onChange={(page) => setParams({ page })}
            onShowSizeChange={(_, limit) => setParams({ limit })}
          />
        </div>
      </AppsContainer>
    </>
  );
};

export default MenuList;
