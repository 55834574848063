import axios from "services/axios";

export const packageList = (params) =>
  axios.get("/packages", {
    params,
  });

export const packageDetail = (id) => axios.get(`/packages/${id}`);

export const packageEdit = (id, values) => axios.patch(`/packages/${id}`, values, {
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

// export const packageCreate = (values) => axios.post("/packages", values, {
//   headers: {
//     "Content-Type": "multipart/form-data",
//   },
// });

export const packageCreate = (values) => axios.post("/packages", values);

export const packageDelete = (id) => axios.delete(`/packages/${id}`);
