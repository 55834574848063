import React, { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, Modal } from "antd";
import AppPageMetadata from "@crema/core/AppPageMetadata";
import AppsContainer from "@crema/core/AppsContainer";
import AppsHeader from "@crema/core/AppsContainer/AppsHeader";
import AppsPagination from "@crema/core/AppsPagination";
import AppsContent from "@crema/core/AppsContainer/AppsContent";
import MenuTable from "./MenuTable";
import { onGetPackageList, onClearPackageList } from "redux/actions/Package";
import { INITIAL_PARAMS } from "shared/constants/InitState";

const PackageList = () => {
  const { loading } = useSelector(({ common }) => common);
  const { packageList } = useSelector(({ package: packageMenu }) => packageMenu);
  const [params, setParams] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    INITIAL_PARAMS
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(onGetPackageList(params));
  }, [dispatch, params]);

  useEffect(
    () => () => {
      dispatch(onClearPackageList());
    },
    [dispatch]
  );

  const onDeleteConfirm = (id) => {
    Modal.confirm({
      title: "HAPUS?",
      content: "Apakah Anda yakin ingin menghapus data tersebut?",
      okText: "Hapus",
      cancelText: "Batal",
      onOk: async () => {
        // await dispatch(onDeleteMenu(id));
        // dispatch(onGetMenuList());
      },
    });
  };

  return (
    <>
      <AppPageMetadata title="Daftar Menu Paket" />
      <AppsContainer title="Daftar Menu Paket" fullView type="bottom">
        <AppsHeader key="wrap">
          <div className="table-header">
            <div className="table-header-input-view">
              <Input
                onChange={({ target }) => setParams({ search: target.value, page: 1 })}
                id="name"
                placeholder="Search"
                type="search"
                name="name"
              />
            </div>
            <div className="table-header-right">
              <Button type="primary" onClick={() => navigate("/packages/create")}>
                Tambah Menu Paket
              </Button>
            </div>
          </div>
        </AppsHeader>

        <AppsContent
          key={"wrap1"}
          style={{
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <MenuTable
            loading={loading}
            data={packageList.data}
            onDetail={(id) => navigate(`/packages/${id}/detail`)}
            onEdit={(id) => navigate(`/packages/${id}/edit`)}
            onDelete={(id) => onDeleteConfirm(id)}
          />
        </AppsContent>

        <div className="apps-footer-table">
          <div>Total Data: {packageList.pagination.totalData}</div>
          <AppsPagination
            pageSize={10}
            count={packageList.pagination.totalData || 0}
            page={packageList.pagination.page || 0}
            onChange={(page) => setParams({ page })}
            onShowSizeChange={(_, limit) => setParams({ limit })}
          />
        </div>
      </AppsContainer>
    </>
  );
};

export default PackageList;
