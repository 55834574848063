export const getToken = () => localStorage.getItem("userToken");

export const setLogin = (token, userData, callback) => {
  localStorage.setItem("userToken", token);
  localStorage.setItem("userData", JSON.stringify(userData));
  callback();
};

export const userData = () => {
  const userData = localStorage.getItem("userData");
  if (userData) {
    const userDataTranslated = JSON.parse(userData);
    return userDataTranslated;
  }
  console.log(userData);
  return null;
};

export const logout = (callback) => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("userData");
  callback();
};

export const RPFormat = (number) => {
  if (!number) return null;
  return "Rp" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const debounce = (callback, time = 500) => setTimeout(() => callback(), time);
