import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import IntlMessages from "@crema/utility/IntlMessages";

import { AiOutlineUser } from "react-icons/ai";
import { FaBirthdayCake } from "react-icons/fa";
import { FiMail, FiPhone } from "react-icons/fi";
import { BsGenderMale } from "react-icons/bs";

const PersonalDetails = ({ data }) => {
  return (
    <div className="contact-detail-modal-item">
      <h6 className="contact-detail-modal-item-title">
        <IntlMessages id="contactApp.personalDetails" />
      </h6>

      <div className="contact-detail-modal-item-content">
        <div className="contact-other-detail-item">
          <AiOutlineUser className="contact-other-detail-item-icon" />
          <div className="contact-other-detail-item-content">
            {data.username}
          </div>
        </div>

        <div className="contact-other-detail-item">
          <FiMail className="contact-other-detail-item-icon" />
          <div className="contact-other-detail-item-content">{data.email}</div>
        </div>

        <div className="contact-other-detail-item">
          <FaBirthdayCake className="contact-other-detail-item-icon" />
          <div className="contact-other-detail-item-content">
            {data.birthDate ? moment(data.birthDate).format("YYYY-MM-DD") : ""}
          </div>
        </div>

        <div className="contact-other-detail-item">
          <BsGenderMale className="contact-other-detail-item-icon" />
          <div className="contact-other-detail-item-content">
            {data.gender === "male" ? "Laki laki" : "Perempuan"}
          </div>
        </div>

        <div className="contact-other-detail-item">
          <FiPhone className="contact-other-detail-item-icon" />
          <div className="contact-other-detail-item-content">{data.phone}</div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;

PersonalDetails.propTypes = {
  data: PropTypes.object.isRequired,
};
