import React from "react";
import "./index.style.less";
import AuthWrapper from "../AuthWrapper";
import AppPageMetadata from "../../../@crema/core/AppPageMetadata";
import SignIn from "./Signin";

const Signin = () => {
  return (
    <AuthWrapper>
      <AppPageMetadata title="Login" />
      <SignIn />
    </AuthWrapper>
  );
};

export default Signin;
