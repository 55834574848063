import axios from "services/axios";

export const reportCompliment = (params, responseType) =>
  axios.get("/report/compliment", {
    params,
    responseType: responseType || "json",
  });

export const reportSales = (params, responseType) =>
  axios.get("/report/sales", {
    params,
    responseType: responseType || "json",
  });
